.pfa-questions-wrapper {
  .ant-checkbox-wrapper {
    display: flex;
    .ant-checkbox {
      margin-top: 3px;
      margin-bottom: 10px;
    }
  }
  .label-wrapper {
    padding-right: 8px;
    .ant-checkbox {
      &.ant-checkbox-checked {
        height: 16px;
        .ant-checkbox-inner {
          background-color: #5cc5e6;
          border: none;
        }
      }
    }
    .pfa-other-answer-textarea {
      background-color: #fff;
      text-overflow: ellipsis;
      color: #000;
      font-size: 14px;
      border-radius: 4px;
      margin-bottom: 10px;
      .ant-input {
        border: 1px solid #d9d9d9;
      }
      &::placeholder {
        font-size: 12px;
        color: #d9d9d9;
        padding-top: 2px;
      }
      &:focus {
        border: 1px solid #d9d9d9;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.pfa-note-wrapper {
  .pfa-note-answer-textarea {
    background-color: #fff;
    text-overflow: ellipsis;
    color: #000;
    margin-bottom: 10px;
    font-size: 14px;
    .ant-input {
      border-radius: 4px;
      min-height: 54px;
      border: 1px solid #d9d9d9;
    }
    &::placeholder {
      font-size: 12px;
      color: #d9d9d9;
      padding-top: 2px;
    }
    &:focus {
      border: 1px solid #d9d9d9;
      outline: none;
      box-shadow: none;
    }
  }
}

.pfa-answers-radio-btn {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  margin-bottom: 30px;
  .ant-radio-wrapper {
    text-align: left;
    background-color: #fff !important;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 10px;
    margin: 0 0 5px 0;
    .ant-radio {
      .ant-radio-inner {
        border: 1px solid #d9d9d9;
      }
    }
    &.ant-radio-wrapper-checked {
      .ant-radio {
        .ant-radio-inner {
          border: 1px solid #008ac2;
          &::after {
            background-color: #008ac2;
          }
        }
      }
    }
  }
}

.submit-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .submit-btn {
    width: 103px;
    height: 44px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #fff;
    background: #6f6f70;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 30px;
    outline: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
}
